import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from '@/helpers/i18n';

// JQuery
import 'jquery/src/jquery.js'

//BootstrapVue 

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// Vuelidate
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

const options = {
  locale: i18n.locale,
  fonts: [
    {
      src: "https://fonts.googleapis.com/css?family=Ruda",
      family: 'Ruda',
      style: 'normal',
    },
  ],
};
Vue.use(options);

import VueMeta from 'vue-meta'
  Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
  })

// Scroll to top on route change
router.beforeEach((to, from, next) => {
  console.log(to);
  console.log(from);
  
  // Scroll to top between each route change
  setTimeout(function () {
      window.scrollTo(0, 0);
    },100);
      
  next();
})

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
