<template>
    <div class="vueperslides__track-inner">
        <div class="vueperslide" :style="{ backgroundImage: 'url(' + imageUrl + ')' }">
            <div class="d-none vueperslide__content-wrapper">
                <hr/>
                <div class="vueperslide__title">
                    <h1>{{heroContent.title.toUpperCase()}}</h1>
                </div>
                <hr/>
                <div class="vueperslide__content">
                    <h5>{{heroContent.content}}</h5>
                </div>
                <div class="mt-4">
                    <a :href="buttonUrlPath" class="btn-border-white text-dec-none">{{heroContent.buttonContent}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { API_HELPER } from "@/helpers/api.js";

export default {
    props: {
        heroContent: {
            type: Object
        }
    },
    computed:{
        imageUrl: function()
        {
            var relativePath = this.heroContent && this.heroContent.image ? this.heroContent.image : null;
            return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]); 
        },
        buttonUrlPath: function()
        {
            return this.heroContent.buttonUrl ? API_HELPER.pathJoin([process.env.VUE_APP_FRONT, this.heroContent.buttonUrl]) : null;
        },
    }
}
</script>

<style scoped>
hr:not([size]) {
    height: 8px;
    width: 70px;
}
hr{
    opacity: 1;
    color: white;
}
</style>